h1 {
  font-size: 80px;
  line-height: 80px;
}

.text-primary {
  color: #26a9df !important;
}

.text-secondary {
  color: #53b272 !important;
}

.padding-top {
  padding-top: 110px;
}

.navbar-padding {
  padding-left: 26px !important;
}
.navbar-padding-2 {
  padding-left: 36px !important;
}

.text-gray {
  color: #636363;
  font-size: 20px;
  font-weight: 500;
}

.paragraph {
  font-size: 20px;
  color: #000000;
}

.btn-primary {
  background-color: #26a9df !important;
  border-color: #26a9df !important;
}

.sub-heading {
  font-size: 36px;
  font-weight: 700;
}

.heading {
  font-size: 40px;
  font-weight: 900;
}

.heading-title {
  font-size: 20px;
  font-weight: 700;
}

/* 
.booking-btn {
  padding: 10px 40px;
  font-size: 24px;
} */
.book-demo-card {
  width: 100%;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 111;
  background-color: #ffffff;
  padding: 10px;
  margin: auto;
  text-align: center;
  box-shadow: 0 -3px 10px rgba(0, 0, 0, 0.16);
}

.nav-booking {
  border-radius: 20px;
  font-weight: 600;
}

@import url("https://fonts.googleapis.com/css2?family=Yesteryear&display=swap");

.font-style {
  font-size: 100px;
  font-weight: 700;
  color: #53b272;
}

body {
  margin: 0;
  font-family: "Lato";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  font: 1em sans-serif;
}

.nav-link {
  color: #53b272;
  font-weight: bold;
}

.one-text {
  color: #5eb77a;
  font-size: 50px;
  font-weight: 900;
}

.one-text-color {
  color: #5eb77a;
}

.relative {
  position: relative;
  top: 51%;
  left: -50px;
}

/* Container and Pattern Layer */
.pattern-layer .pattern-3 {
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  z-index: -1;
}

/* Header Text */
.main-text {
  font-family: "pass through";
  font-weight: bold;
  font-size: 3rem;
  color: #26a9df;
}

.navbar-text {
  font-family: "pass through";
  color: #333;
}

p {
  color: #333;
}

/* Image Styles */

.background-container {
  position: relative;
  padding: 20px;
  background-image: url("../src/images/shadow.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.hero-bg-image::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 10%);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.hero-bg-image {
  position: relative;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  background-image: url("../src/images/Group 220 1.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: calc(100vh - 100px);
}

.main-section {
  height: "calc(100vh - 100px)";
}

.hero-bg-image::after {
  /* content: ""; */
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.02);
}

.hero-bg-image .paragraph {
  max-width: 500px;
}

/* Button Styles */
.button {
  background-color: #26a9df;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/*roadmap*/

.roadmap-img {
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  height: calc(100vh - 70px);
  /* height: auto; */
  /* background-image: url("../src/images/roadmap.svg"); */
  /* background-size: cover;
  background-repeat: no-repeat;
  background-position: center; */
  /* background: linear-gradient(180deg, rgba(38, 169, 223, 0) 39%, #26A9DF 100%); */
}

.roadmap-text-container {
  text-align: left;
  position: absolute;
  top: 65px;
  left: 7px;
  margin-bottom: -100px;
}

/*about us*/

.about-us-section {
  padding-bottom: 80px;
}

.about-img img {
  width: 100%;
  max-width: 100%;
  height: auto;
  max-height: 595px;
  object-fit: contain;
}

.hero-techbase-bg-image {
  justify-content: center;
  align-items: center;
  display: flex;
  background-position: center;
  background: linear-gradient(90deg, rgba(83, 178, 114, 0) 42%, #53b272 72%);
  padding-top: 80px;
  padding-bottom: 80px;
}

.techbase-girl {
  width: 100%;
  margin-left: auto;
  display: block;
  height: 500px;
  object-fit: contain;
}

.techbase-image {
  background-color: #26a9df;
  color: #fff;
  border: #26a9df;
  border-radius: 100%;
  margin: 0 auto;
  text-align: center;
  width: 100px;
  height: 100px;
  padding: 20px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.techbase-image img {
  object-fit: contain;
  width: 100%;
}

/*some fact*/
.somefact-bg-image {
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  padding-top: 80px;
  padding-bottom: 80px;
  box-shadow: inset 0 0 0 1000px rgba(248, 248, 248, 0.8);
  background-image: linear-gradient(to top, #4eae6c73, #248ccc8a);
}

.somefact-bg-text {
  font-weight: 600;
  text-align: center;
  font-size: 20px;
  color: rgb(108 108 108);
}

.google-rating {
  width: 200px;
  height: 60px;
}

/*services*/
.services-section {
  background: linear-gradient(73.05deg, #eff9fe 1.29%, #ffffff 58.81%);
  padding-bottom: 66px;
}

.image-wrapper {
  border: 1px solid #c3bfbf;
  border-radius: 20px;
  height: 100%;
  padding: 20px;
}

.services-image {
  height: auto;
  margin: 0 auto 20px;
  max-width: 200px;
  width: auto;
}

/*video*/
.video-img {
  border-radius: 40px;
}

.video-section .video-img {
  max-height: 600px;
  border: 1px solid #f8f9fa;
}

.testimonial-image .video-img,
.what-back-pain-section .video-img {
  border-radius: 20px;
}

/*diagnosis*/
.diagnosis-section {
  padding: 80px 0;
  background: linear-gradient(
    279.76deg,
    #eff9fe 47.71%,
    rgba(255, 255, 255, 0) 62.47%
  );
}

.diagnosis-image img {
  width: auto;
  height: auto;
  max-width: 100%;
}

.diagnosis-text {
  font-size: 35px;
  font-weight: 900;
}

.diagnosis-list {
  column-count: 2;
  font-size: 20px;
  font-weight: 700;
}

/*aalayam*/
.somefact-aalayam-bg-image {
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
  /* background-image: url("../src/images/aalayam.webp"); */
  /* background-size: cover;
  background-repeat: no-repeat;
  background-position: center; */
  padding-top: 80px;
  padding-bottom: 80px;
  box-shadow: inset 0 0 0 1000px rgba(248, 248, 248, 0.8);
  background-image: linear-gradient(to top, #4eae6c73, #248ccc8a);
}

/*treatment*/
.treatment-text {
  font-size: 16px;
  font-weight: bold;
}

.text-style-secondary {
  font-size: 25px;
}

.text-container {
  position: absolute;
  top: 5%;
  right: 16%;
  text-align: right;
}

/* appointment */
.appointment-section {
  padding-bottom: 80px;
}

.appointment-bg-image {
  padding: 80px 30px;
  background-color: #26a9df;
  border-radius: 40px;
}

.form-control {
  background-color: transparent;
  border: 1px solid #ffffff;
  border-radius: 10px;
  padding: 15px;
  margin-bottom: 24px;
}

.form-control:focus {
  background-color: transparent;
  box-shadow: none;
  border: 1px solid #ffffff;
}

.form-control::placeholder {
  color: #ffffff;
  opacity: 1;
}

.btn-white {
  background-color: #f8f8f8;
  border: none;
  padding: 10px 90px;
  font-size: 26px;
  font-weight: 800;
  border-radius: 10px;
  cursor: pointer;
  gap: 10px;
}

/*solution*/
.solution-wrapper {
  border: 1px solid #f8f8f8;
  border-radius: 10px;
  box-shadow: 0px 1px 2px 0px #0000004d;
}

.solution-image {
  padding: 20px 0px 20px 20px;
  align-items: center;
  justify-content: center;
  height: auto;
  display: flex;
  margin: 0 auto;
}

.solution-image-text {
  margin-left: 150px;
  font-weight: bold;
}

.solution-steps {
  line-height: normal;
  margin: 20px;
  display: flex;
  align-items: self-start;
}

.solution-steps .paragraph {
  width: calc(100% - 70px);
}

.solution-img img {
  width: 100%;
  max-width: 100%;
  height: auto;
  max-height: 300px;
  object-fit: contain;
}

/* testimonial */
.testimonial-box {
  background-position: bottom;
  background: linear-gradient(180deg, rgba(38, 169, 223, 0) 5%, #26a9df 100%);
  padding-bottom: 100px;
}

.carousel-indicators {
  display: none;
}

.testimonial-image {
  align-items: center;
  justify-content: center;
  height: auto;
  display: flex;
  margin: 0 auto;
  border-radius: 20px;
  /* height: 552px; */
}

.testimonial-img {
  height: 480px;
  width: 350px;
}

.testimonial-font {
  font-size: 18px;
}

.testimonial-card {
  border: 1px solid #c3bfbf;
  background-color: #fff;
  padding: 40px;
  margin: 0 auto;
  border-radius: 20px;
  height: 100%;
  margin-right: 20px;
}

.testimonial-card-content,
p.paragraph.testimonial-font {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.testimonial-item {
  height: 100%;
}

.testimonial-section .swiper-slide {
  height: auto;
}

.board-section {
  padding-top: 80px;
  padding-bottom: 60px;
}

/* footer */

.founded {
  margin-left: auto;
  display: block;
}

.founded-container {
  margin-right: 200px;
}

.footer-section {
  background-color: #26a9df;
}

.footer-text {
  font-size: 18px;
}

.footer-img {
  height: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 18px;
  margin-bottom: 20px;
  margin-left: -7px;
}

.footer-link {
  color: #53b272;
  width: 100%;
  height: 141px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
}

.footer-input {
  background-color: #f8f8f8;
  border: none;
  padding: 10px;
  border-radius: 10px;
  width: 280px;
  height: 57px;
  margin-right: 16px;
}

.footer-logo {
  width: 240px;
  height: 130px;
  /* border: #f8f8f8 solid 1px; */
  /* padding: 10px; */
  /* background-color: #ffffff; */
  /* border-radius: 10px; */

  display: block;
}

.footer-sub-heading {
  font-size: 28px;
  font-weight: bold;
  max-width: 360px;
}

.image-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.image-container img {
  margin-top: 10px;
  width: auto;
  max-width: 176px;
  object-fit: contain;
}

.subscribe-btn {
  background-color: #53b272;
  border: none;
  border-radius: 10px;
  width: 141px;
  height: 57px;
}

.webLink {
  background-color: #53b272;
  height: 72px;
}

.link-web {
  padding-top: 20px;
}

.treatment-section .swiper {
  padding-bottom: 70px;
  padding-top: 30px;
}

.treatment-card {
  border-radius: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.175);
}

.image-video-container {
  /* aspect-ratio: 3 / 2; */
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  height: 233px;
}

.image-video-container .image {
  width: auto;
  height: auto;
  object-fit: cover;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  min-width: 100%;
  min-height: 100%;
}

.image-video-container .video {
  height: 100%;
  width: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.treatment-section .slick-list .slick-slide {
  padding: 30px;
}

.treatment-card .card-body {
  padding: 20px;
}

.treatment-section .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background-color: #278bcb;
}

.treatment-section .swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 22px;
}

@media (min-width: 1024px) {
  .treatment-section .swiper {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 991px) {
  .image-container {
    margin-bottom: 20px;
  }

  .testimonial-box {
    padding-bottom: 50px;
  }

  .roadmap-text-container {
    top: 80px;
  }
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .main-text {
    font-size: 2rem;
  }

  .button {
    width: 100%;
    text-align: center;
  }

  .footer-logo {
    margin-left: auto;
    margin-right: auto;
  }
}

.diagnosis-list li {
  padding: 5px 0;
}

.pr-0 {
  padding-right: 0;
}

.pl-0 {
  padding-left: 0;
}
@media (max-width: 575px) {
  .home-page {
    padding-bottom: 58px;
  }
}

@media (max-width: 512px) {
  .image-video-container {
    height: 200px;
  }
}

@media (max-width: 480px) {
  .heading-title {
    font-size: 16px;
  }

  .subscribe-btn {
    width: 120px;
  }

  .footer-input {
    margin-right: 8px;
  }

  .heading-title {
    font-size: 18px !important;
    line-height: 20px;
  }
}

.somefaq-bg-text {
  font-size: 18px;
  color: #2e2e2e;
  text-align: left;
  font-weight: 400;
}

.btn-Services {
  position: relative;
  margin-bottom: 1px;
}

.servicesPara {
  position: relative;
  bottom: 0px;
  /* width: 80%; */
}

.image-wrapper-services {
  border: 1px solid #c3bfbf;
  border-radius: 20px;
  height: 100%;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}
.services-grid-image {
  height: max-content;
}
.service-description {
  font-size: 14px;
}

@media (max-width: 480px) {
  .text-container {
    right: 5% !important;
  }
}
.class-service-loader {
  height: calc(100vh - 90px) !important;
}
@media (max-width: 991px) {
  .class-service-loader1 {
    height: calc(100vh - 200px) !important;
  }
}
@media (max-width: 769px) {
  .class-service-loader1 {
    height: calc(100vh - 290px) !important;
  }
}
