html {
  overflow-x: hidden;
}

html,
body,
#root {
  height: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.privacy-policy-page {
  background-color: #f7f7f7f7;
  padding: 50px 0;
}

.privacy-headig {
  padding: 20px 0;
}

.privacy-policy-sub-heading {
  margin-top: 50px;
}

.primary-color {
  color: #6ab782;
}

.privacy-headig p {
  margin-bottom: 1rem;
  margin-top: 0;
}

.refund-policy-sub-sec {
  line-height: 35px;
  padding-top: 50px;
}

.cancellation-policy-sub-sec {
  padding-bottom: 50px;
}

.cancellation-policy-sub-sec,
.refund-policy-sub-sec {
  line-height: 35px;
  padding-top: 50px;
}

.privacy-headig {
  padding: 20px 0;
}

.privacy-headig p {
  color: #333;
}

ul {
  margin-bottom: 1rem;
  margin-top: 0;
}

.fut-rout li a {
  text-decoration: none;
}

li::marker {
  color: #fff;
}

.fut-rout li a:hover {
  /* color: #6ab782 !important; */
  text-decoration: none;
}

.what-back-pain-section {
  padding: 100px 0;
}

.what-back-pain-section h2,
.symptoms-section h2 {
  font-weight: 800 !important;
}

.backpain-page .testimonial-section .padding-top {
  padding-top: 80px;
}

.backpain-hero-section {
  background-image: linear-gradient(0deg, #4eae6c73, #248ccc8a);
  box-shadow: inset 0 0 0 1000px #f8f8f8cc;
  padding: 80px 0;
}

.symptoms-section {
  padding-bottom: 100px;
}

.symptoms-section .diagnosis-list li::marker {
  color: #53b272;
}

.symptoms-img {
  /* height: 400px; */
  overflow: hidden;
  border-radius: 20px;
}

.symptoms-img img {
  height: auto;
  object-fit: contain;
  border-radius: 20px;
  height: 100%;
  width: auto;
}

.treatment-card .card-title {
  color: #53b272;
  font-size: 24px;
  text-transform: capitalize;
  font-weight: 600;
}

.treatment-card .card-img-top {
  height: 300px;
  object-fit: cover;
}

.treatment-card .card-text {
  font-size: 18px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media (min-width: 2200px) {
  .hero-bg-image {
    height: 1200px;
  }
}

@media (min-width: 992px) {
  .container-lg-fluid {
    max-width: 100%;
  }

  .what-back-pain-section h2,
  .symptoms-section h2 {
    font-size: 50px !important;
  }

  /* .symptoms-img img {
    object-position: 0 -20px;
  } */
}

@media (max-width: 1199px) {
  .hero-bg-image {
    height: 550px;
  }

  .solution-font {
    font-size: 18px;
  }

  .one-text {
    font-size: 32px;
    line-height: normal;
  }

  .tech-rank-image {
    position: relative;
    top: -219px;
    left: 53px;
  }
}

@media (max-width: 991px) {
  .solution-font {
    font-size: 22px;
  }

  .booking-btn {
    padding: 10px 30px;
    font-size: 16px;
  }

  .tech-rank-image {
    position: relative;
    position: relative;
    top: -289px;
    margin-left: 127px;
  }

  .somefact-bg-image,
  .hero-techbase-bg-image,
  .somefact-aalayam-bg-image,
  .board-section {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .padding-top {
    padding-top: 50px;
  }

  .what-back-pain-section {
    padding: 50px 0;
  }

  .backpain-hero-section {
    padding: 60px 0;
  }

  .backpain-page .testimonial-section .padding-top {
    padding-top: 0;
  }

  .appointment-section,
  .symptoms-section {
    padding-bottom: 50px;
  }

  .hero-bg-image .padding-top {
    padding-top: 0;
  }

  .appointment-bg-image {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .about-us-section {
    padding-bottom: 0;
  }

  .services-section {
    padding-bottom: 30px;
  }

  .hero-techbase-bg-image {
    /* background: linear-gradient(180deg, rgba(83, 178, 114, 0) 42%, #53b272 72%); */
    background: linear-gradient(180deg, rgba(83, 178, 114, 0) 65%, #53b272 86%);
  }

  .techbase-girl {
    max-width: 400px;
    margin-right: auto;
    height: auto;
    margin-top: 20px;
  }

  .treatment-section .swiper {
    padding-bottom: 50px;
    padding-left: 20px;
  }

  .home-page .testimonial-image {
    height: 600px;
  }

  .diagnosis-section {
    padding: 50px 0;
  }

  .solution-img {
    margin-top: 30px;
  }
}

@media (max-width: 767px) {
  .hero-bg-image {
    height: 400px;
  }

  .hero-bg-image .paragraph {
    max-width: 270px;
  }

  .booking-btn {
    padding: 11px 19px;
    font-size: 10px;
  }

  .paragraph {
    font-size: 16px;
    color: #000000;
  }

  .solution-steps {
    margin: 15px;
    font-size: 16px;
  }

  .solution-steps .paragraph {
    width: calc(100% - 60px);
  }

  .heading-title {
    font-size: 20px !important;
  }

  .somefact-bg-text {
    font-size: 20px;
  }

  .testimonial-card {
    padding: 20px;
  }

  .diagnosis-list {
    column-count: 1;
  }

  .font-style {
    font-size: 50px;
  }

  .image-container img {
    margin-top: 10px;
    width: 90%;
  }

  .footer-sub-heading {
    margin: auto;
    font-size: 15px;
  }

  .video-img {
    border-radius: 20px;
  }

  .treatment-card .card-title {
    font-size: 22px;
  }

  .roadmap-text-container {
    top: 60px;
  }

  .home-page .testimonial-image {
    height: 440px;
    overflow: hidden;
    padding: 0;
  }

  .home-page .testimonial-section .col-lg-4.col-sm-6.col-12.text-center {
    width: 50%;
  }

  .treatment-section .swiper {
    padding-bottom: 100px;
  }

  .treatment-section .swiper-pagination {
    bottom: 40px !important;
  }
}

@media (max-width: 575px) {
  .solution-font {
    font-size: 18px;
  }

  .booking-btn {
    padding: 11px 19px;
    font-size: 14px;
  }

  .somefact-bg-text {
    font-size: 18px;
  }

  .hero-bg-image {
    height: 310px;
  }

  .services-section .services-image {
    max-width: 100px;
    height: 100px;
  }

  .heading-title {
    font-size: 18px !important;
    line-height: 20px;
  }

  .text-gray {
    font-size: 16px;
  }

  .treatment-card .card-text {
    font-size: 16px;
    line-height: 20px;
  }

  .testimonial-video-section .swiper {
    padding-left: 20px;
  }

  .symptoms-img {
    max-height: 250px;
  }
}

@media (max-width: 480px) {
  .solution-font {
    font-size: 14px;
  }

  .booking-btn {
    display: block;
    width: 120px;
  }

  .hero-bg-image .paragraph {
    max-width: 210px;
  }

  .hero-content {
    margin-top: -52px;
  }

  .one-text {
    font-size: 20px;
  }

  .somefact-bg-text {
    font-size: 18px;
  }

  .testimonial-card {
    height: auto;
  }

  .hroadmap-img {
    height: 400px;
  }

  .booking-btn {
    padding: 12px 8px;
    font-size: 14px;
  }

  .sub-heading {
    font-size: 18px;
    font-weight: 600;
  }

  .heading {
    font-size: 24px;
    font-weight: 900;
    line-height: 30px;
  }

  .google-rating {
    width: 120px;
    height: 45px;
  }

  .btn-white {
    background-color: #f8f8f8;
    border: none;
    border-radius: 10px;
  }

  /*treatment*/
  .treatment-text {
    font-size: 11px;
    font-weight: bold;
  }

  /*appoiment*/
  .btn-white {
    padding: 10px 17px;
    font-size: 14px;
  }

  .tech-rank-image {
    position: relative;
    top: -155px;
    margin-left: 60px;
  }

  .tech-rank-image img {
    width: 76px;
    height: 97px;
  }

  /*footer*/
  .footer-text {
    font-size: 16px;
  }

  .home-page .testimonial-section .col-lg-4.col-sm-6.col-12.text-center {
    width: 100%;
  }

  .home-page .testimonial-image video {
    width: 226px !important;
  }

  .home-page .testimonial-image {
    height: 400px;
  }
}

@media (max-width: 380px) {
  .booking-btn {
    display: none;
  }
}

.whatsapp-button {
  position: fixed;
  bottom: 50%;
  right: 20px;
  background-color: #25d366;
  color: white;
  border: none;
  padding: 0px 15px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 1000;
  width: 60px;
  height: 60px;
  transition: width 0.5s ease;
}

.whatsapp-button svg {
  width: 30px;
  height: 30px;
}

.wtspp-hide {
  opacity: 0;
  overflow: hidden;
  white-space: nowrap;
  transition: opacity 0.5s ease, visibility 0.5s ease;
}

.whatsapp-button:hover {
  width: 200px;
}

.whatsapp-button:hover .wtspp-hide {
  opacity: 1;
  overflow: visible;
}

.trade-mark {
  color: #000000;
  font-weight: normal;
}

.whatsapp-icon {
  margin-right: 10px;
}

.form-select {
  background-color: #26a9df !important;
  color: #f8f8f8;
}

.testimonial-card {
  height: 300px !important;
}

.swiper-wrapper,
.center-content {
  justify-content: center !important;
}

@media (max-width: 767px) {

  .testimonial-section .slick-prev:before,
  .testimonial-section .slick-next:before {
    font-size: 30px;
  }

  .testimonial-section .slick-prev,
  .testimonial-section .slick-next {
    bottom: -40px;
    transform: translate(0, 0);
    top: auto;
  }

  .testimonial-section .slick-prev {
    left: 42%;
    right: auto;
    margin: auto;
  }

  .testimonial-section .slick-next {
    right: 44%;
    left: auto;
    margin: auto;
  }

  .treatment-section .swiper-wrapper {
    justify-content: start !important;
  }
}

@media (max-width: 400px) {
  .testimonial-section .slick-next {
    right: 40%;
  }

  .testimonial-section .slick-prev {
    left: 40%;
    right: auto;
    margin: auto;
  }

  .roadmap-text-container {
    top: 20px;
  }
}

@media (max-width: 512px) {
  .roadmap-img {
    height: auto;
  }

  .roadmap-text-container {
    top: 15px;
  }
}

/* Popup.css */

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  color: white;
  max-width: 500px;
  text-align: center;
}

.popup-content h2 {
  margin: 0;
  font-size: 24px;
}

.popup-content p {
  font-size: 16px;
}

.popup button {
  background-color: #25d366;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

button.close-btn {
  float: right;
  background-color: transparent;
}

.popup-bg-image {
  padding: 30px 15px 30px 50px;
  background-color: #26a9df;
  border-radius: 40px;
}

.popup-submit-btn {
  font-size: 14px;
}

.accordion-button {
  font-size: 22px;
  font-weight: 700;
  line-height: 22px;
  color: #656565;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.accordion {
  --bs-accordion-active-bg: none !important;
  border-color: white !important;
  box-shadow: none !important;
}

.accordion-button:focus {
  box-shadow: none !important;
  border-color: #e5e5e5 !important;
}

.accordion-button:not(.collapsed) {
  color: #2e2e2e !important;
}

.service-video video {
  height: 400px;
  border: 1px solid #dddddd;
}

@media (max-width: 512px) {
  .whatsapp-button {
    bottom: 15%;
    right: 5px;
  }
}

.btn-appointment {
  margin-left: 36px;
  font-size: 14px;
  font-weight: bold;
  font-family: sans-serif;
}

.text-size-footer {
  font-size: 16px;
}

@media (max-width: 765px) {
  .footer-section .text-center {
    width: 70%;
    margin: 0 auto;
  }
}

.text-footer-describe {
  display: flex;
}

@media (max-width: 1023px) {
  .text-footer-describe {
    display: block;
  }
}

.footer-contact {
  display: block;
}

@media (max-width: 1023px) {
  .footer-contact {
    justify-content: center;
    display: flex;
    flex-direction: column;
    /* width: 50%; */
    margin: 0 auto;
    padding: 40px;
    padding-top: 0px;
    padding-bottom: 0px;
    text-align: center;
  }
}

.class-mobile-socialmedia {
  display: block;
}

@media (max-width: 575px) {
  .class-mobile-socialmedia1 {
    display: block;
  }
}

.service-other-links {
  text-align: left;
}

.link-web-service {
  padding: 0px;
  margin: 0px;
}

@media (max-width: 576px) {
  .service-nav-footer {
    margin-bottom: 55px;
  }
}

.navbar .dropdown-item:focus,
.navbar .dropdown-item:hover {
  background-color: #f8f9fa !important;
}

.loading {
  background: linear-gradient(0deg, #4eae6c73, #248ccc8a);
}

.app-loader img {
  width: auto;
  max-width: 60px;
}